
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


















































































































































































.l-footer {
    margin-top: 10vh;

    @media #{md("md", "max")} {
        padding-bottom: 4em;
    }

    @media #{md("xs", "max")} {
        padding-bottom: 5em;
    }

    @media #{md("md", "max")} {

        .c-btn-group {
            display: none;
        }
    }
}

.l-footer__copy {
    min-width: 14em;
}

.l-footer__images {
    padding-bottom: 2em;

    .o-asset {
        width: 70%;
        height: 0;
        padding-top: 70%;
        border-radius: 50%;
        overflow: hidden;

        &:nth-child(1) {
            z-index: 2;
        }

        &:nth-child(2) {
            z-index: 1;
            margin-top: -40%;
            margin-left: calc(100% - 70%);
        }
    }

    .o-deco {

        &--dots {
            --size: 70%;

            bottom: 0;
            left: 15%;
        }

        &--lines {
            --color: #{$color-primary-light};
            --size: 50%;

            top: 0;
            right: 5%;
        }

        &--plus {
            z-index: 2;
            position: absolute;
            top: calc(70% - var(--size)/2 - 2em);
            left: calc(65%/2 - var(--size)/2);
        }
    }

    @media #{md("sm", "max")} {
        width: calc(2/3 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("xs", "max")} {
        width: calc(11/12 * 100%);
    }
}

.l-footer__bottom {
    margin-top: 1em;
    margin-bottom: 1em;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }

    @media #{md("xs")} {
        display: flex;
        justify-content: space-between;
    }

    @media #{md("sm")} {
        display: grid;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-end;
        margin-top: 5vh;
    }

    @media #{md("md")} {
    }
}

.l-footer__desc {
    display: inline-block;

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }

    @media #{md("md")} {
        margin-left: calc(1/6 * 100%);
    }
}



/*----------  Sitemap  ----------*/

.o-sitemap {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("sm", "max")} {
        margin-top: 5vh;
    }

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr)
    }
}

.o-sitemap__item {}

.o-sitemap__title {
    display: block;
    margin-bottom: .5em;
    font-size: 1em;
    font-weight: 400;
    color: $color-secondary;
}

.o-sitemap__list {
    @include reset-list;

    li {
        margin-top: .25em;
        margin-bottom: .25em;
    }
}



/*----------  Social list  ----------*/

.o-social {
    @include reset-list;

    display: flex;
    margin-top: 5%;
    margin-bottom: 7.5%;

    @media #{md("sm")} {
        margin-top: 10%;
        margin-bottom: 15%;
    }
}

.o-social__item {
}

.o-social__link {
    display: block;

    &:hover {

        .o-social__icon {
            color: $color-primary;
            transform: scale(1.1);
            transition: all .3s ease-out;
        }
    }
}

.o-social__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    height: 2.75em;
    transition: all .2s ease-in;
}


