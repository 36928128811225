/*============================
=            Main            =
============================*/

.l-wrapper {}

.l-wrapper__inner {
}

.l-main {
    padding-top: $header-height;

    @media #{md("md", "max")} {
        overflow-x: hidden;
    }
}


/*----------  Tag  ----------*/

.o-tag {
    display: inline-block;
    padding: 0.45em 0.45em 0.35em;
    line-height: 1;
    font-size: 0.85em;
    color: $color-secondary;
    text-align: center;
    background-color: $color-primary-light;
    border-radius: $border-radius;

    &.--round {
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        padding: .25em;
        font-size: .7rem;
        line-height: 1em;
    }
}

