/*==========================================
=            Flickity overrides            =
==========================================*/


// Dots
.flickity-page-dots {
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 2em;

    .dot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        margin: 0;
        opacity: 1;
        background: none;

        &:before {
            @include pseudo-el($width: .4em, $height: .4em, $bg: $color-primary-light);
            border-radius: 50%;
            transition: all .2s ease-in-out;
        }

        &:hover {

            &:before {
                transform: scale(2);
            }
        }

        &.is-selected {

            &:before {
                background-color: $color-primary;
                transform: scale(2);
            }
        }
    }
}

