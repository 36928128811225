
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































































.o-deco {
    --perc: 1;
    --size: 10em;
    --color: #{$color-primary};

    --lines: 1;
    --line-width: .6rem;

    position: absolute;
    display: block;

    &--plus {
        --size: 3.75em;
    }

    &--dots,
    &--circle {
        width: var(--size);
    }

    &--lines {
        width: calc(var(--lines) * (var(--line-width) * 2) - var(--line-width));
        height: var(--size);
    }

    &--lines-horz {
        width: var(--size);
        height: calc(var(--lines) * (var(--line-width) * 2) - var(--line-width));

        .o-deco__lines {
            flex-direction: column;
        }

        .o-deco__line {
            width: 100%;
            height: var(--line-width);
            transform-origin: 0 50%;

            &:nth-child(n+2) {
                margin-top: var(--line-width);
                margin-left: 0;
            }
        }
    }

    /*----------  Animations  ----------*/

    &--dots {
        width: calc(var(--size) + (var(--size) * var(--perc, 0))/5);
    }
}

.o-deco__plus {
    display: block;
    transform: rotate(calc(var(--perc, 0) * 90deg));

    svg {
        width: var(--size);
        height: var(--size);
    }
}

.o-deco__dots,
.o-deco__circle {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
}

// Dots
.o-deco__dots {
    background-image:
        radial-gradient(circle, var(--color) 5%, transparent 6%),
        radial-gradient(circle, var(--color) 5%, transparent 6%);
    background-size: 2.2rem 1.5rem;
    background-position: 25% 25%, calc(2.2rem/2 + 25%) calc(1.5rem/2 + 25%);
    background-repeat: repeat;
}

// Circle
.o-deco__circle {
    background-color: var(--color);
}

// Line
.o-deco__lines {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.o-deco__line {
    flex-shrink: 0;
    height: 100%;
    width: var(--line-width);
    background-color: var(--color);
    transform-origin: 50% 0;
    will-change: transform;

    &:nth-child(n+2) {
        margin-left: var(--line-width);
    }

    @for $i from 1 through 15 {

        &:nth-child(#{$i}) {
            transform: scale(1, var(--perc, .5)) translate(0, calc((1 - var(--perc, .5)) * #{$i} * 100%/var(--lines)));

            .o-deco--lines-horz & {
                transform: scale(var(--perc, .5), 1) translate(calc((1 - var(--perc, .5)) * #{$i} * 100%/var(--lines)), 0);
            }
        }
    }
}

