/*=================================
=            Selection            =
=================================*/


::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*=======================================
=            Global settings            =
=======================================*/


:root {

    // Grid gutter
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            --grid-gutter: #{map-get($grid-gutters, $key)};
            --grid-gutter-half: calc(var(--grid-gutter)/2);
        }
    }
}

*,
*:after,
*:before {
	position: relative;
	outline: none;
}


html {
	font-size: $font-size;

//    // Media queries
//    @media #{md("xs")} {
//        font-size: $font-size + 2;
//    }
//
//    @media #{md("lg")} {
//    	font-size: $font-size + 3;
//    }
//
//    @media #{md("xl")} {
//    	font-size: $font-size + 4;
//    }
//
//    @media #{md("xxl")} {
//    	font-size: $font-size + 5;
//    }

    // Better font rendering for mac
    &.is-mac-os {
        @include antialiased;
    }
}

body {
    width: 100%;
    //overflow-x: hidden;
}
