
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/


// Socials
.svg-facebook,
.svg-instagram {
    width: 1.25em;
    height: 1.25em;
}

.svg-linkedin {
    width: 1.25em;
    height: 1.31em;
}

// Logos
.svg-logo {
    width: 14.81em;
    height: 3.75em;

    --fill-icon: #{$color-primary};
}

.svg-logo-text,
.svg-logo-icon {
    width: 3.75em;
    height: 3.75em;
}

// Icons
.svg-arrow-right {
    width: 1.5em;
    height: 1.5em;

    --fill-bg: #{$color-primary};
    --fill-icon: #{$color-light};
}

.svg-arrow-down {
    width: .5em;
    height: .31em;
}

.svg-plus {
    width: 3.75em;
    height: 3.75em;

    --fill-bg: #{$color-primary};
    --fill-icon: #{$color-light};
}

.svg-clock {
    width: 1em;
    height: 1em;
}

.svg-directions {
    width: 1.5em;
    height: 1.5em;
}

.svg-star {
    width: 1em;
    height: 1em;
}

.svg-search {
    width: .9em;
    height: .9em;
}

.svg-lily {
    width: 1.5em;
    height: 2.15em;
}

.svg-share {
    width: .75em;
    height: .75em;
}

