/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    line-height: $line-height;
    font-family: ff("sans");
    color: $font-color;
}


/*==============================
=            Titles            =
==============================*/


.t-t1,
.t-t2,
.t-t3,
.t-t4,
.t-t5, {
    font-weight: 700;
}

.t-t1 {
    line-height: 1;
    font-size: 3em;

    hyphens: auto;

    @media #{md("sm")} {
        font-size: 3.5em;
        hyphens: none;
    }

    @media #{md("lg")} {
        font-size: 4em;
    }
}

.t-t2 {
    line-height: 1;
    font-size: 2.125em;

    @media #{md("sm")} {
        font-size: 2.6em;
    }

    @media #{md("lg")} {
        font-size: 3em;
    }
}

.t-t3 {
    line-height: 1;
    font-size: 1.375em;

    @media #{md("sm")} {
        font-size: 1.75em;
    }

    @media #{md("lg")} {
        font-size: 2.125em;
    }
}

.t-t4 {
    line-height: 1.4;
    font-size: 1.125em;

    @media #{md("sm")} {
        font-size: 1.2em;
    }

    @media #{md("lg")} {
        font-size: 1.375em;
    }
}

.t-t5 {
    line-height: 1.4;
    font-size: 1em;

    @media #{md("sm")} {
        font-size: 1.08em;
    }

    @media #{md("lg")} {
        font-size: 1.125em;
    }
}

.t-tbody {
    font-size: 1em;
    font-weight: 700;
}

.t-tsmall {
    font-size: .8em;
    font-weight: 400;
}

.t-heading {

    strong {
        display: inline;
        color: $color-primary;
        background-image: linear-gradient(transparent calc(100% - .4em), $color-primary-light calc(100% - .4em), $color-primary-light calc(100% - .15em), transparent calc(100% - .15em));
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.t-link,
.t-cms a {
    display: inline;
    background-image: linear-gradient(transparent calc(100% - .4em), $color-primary-light calc(100% - .4em), $color-primary-light calc(100% - .15em), transparent calc(100% - .15em));
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: color .4s ease-out, transform .4s ease-out, background-size .8s $out-expo;

    &:hover {
        color: $color-primary;
        background-size: 100% 100%;
    }
}


/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    h1,
    h2 {
        @extend .t-t4;
        color: $color-secondary;

        strong {
            font-weight: inherit;
        }
    }

    h3 {
        @extend .t-t5;
        color: $color-secondary;
        font-weight: 400;

        // UX/UI correction
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }

        br { display: none }

        strong {
            font-weight: inherit;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 1.414em 0 .5em;
        line-height: 1.2;
    }

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    a {
        color: $color-secondary;

        &:hover {
        }
    }

    ul, ol {

        ul, ol {
            margin-top: .5em;
            margin-bottom: 1em;
        }
        li {
            margin-bottom: .5em;
            line-height: 1.25em;
        }
    }

    ul {
        padding-left: .25em;

        li {
            list-style-type: none;
            padding-left: .75em;

            &:before {
                @include pseudo-el($width: .25em, $height: .25em, $display: inline-block, $bg: $color-primary);
                position: absolute;
                top: .5em;
                left: 0;
                border-radius: .25em;
            }
        }
    }

    ol {
        padding-left: 1.25em;
        text-align: left;

        li {
            padding-left: .5em;
        }

        ol {
            list-style-type: lower-alpha;
        }
    }

    img {
        max-width: 100%;
    }

    > figure, > img {
        margin-top: $line-height * 2em;
        margin-bottom: $line-height * 2em;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            display: block;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


small,
.t-small {
    font-size: .875em;
}

.t-uppercase {
    text-transform: uppercase;
}

sup {
    vertical-align: super;
    font-size: .875em;
}

a {
    color: inherit;
    text-decoration: none;
}

.t-blockquote {
    display: inline-block;
    padding-top: .2em;
    padding-bottom: .2em;
    color: $color-secondary;
    border-left: .1em solid $color-secondary;
    background-color: $color-primary-light;

    span {
        display: inline-block;
        margin: .2em .5em;
    }
}
