
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
// Base
@import "base/variables";
@import "base/ress"; // {ress|normalize|reset}

// Tools
@import "tools/functions";
@import "tools/mixins";
@import "tools/utilities";

// Debug
// @import "tools/debug-outline";

/*! purgecss start ignore */

// Layout
@import "layout/grid";
@import "layout/typography";
@import "layout/global";
@import "layout/main";

// Plugins
@import "plugins/flickity";

/*! purgecss end ignore */
