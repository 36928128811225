/*=======================================
=            Global settings            =
=======================================*/


// Colors
$color-dark: #3a443c;
$color-light: #ffffff;
$color-green: #5fb727;
$color-green-light: #e4efe9;
$color-blue: #5a9b84;
$color-blue-light: #c2d3cd;
$color-grey: #9ca19d;

$color-primary: $color-green;
$color-primary-light: $color-green-light;
$color-secondary: $color-blue;
$color-secondary-light: $color-blue-light;

$font-color: $color-dark;
$selection-bg: $color-secondary;
$selection-color: $color-light;


// Typography
$font-families: (
    "sans": ("Steradian", "Helvetica Neue", Arial, sans-serif),
);

$font-size: 16px;
$line-height: 1.45;

// Fontfaces
$font-dir: "/static/fonts/";

$fontfaces: (
    "Steradian" "steradian-regular" 400 normal,
    "Steradian" "steradian-medium" 600 normal,
);

// Border
$border-radius: .5rem;

$border-light: 1px solid $color-primary-light;
$border-secondary : 1px solid $color-blue-light;
$border-thick-width: .4rem;
$border-thick-color: $color-primary-light;

// Layout
$header-height: 5.25rem;



/*============================
=            Grid            =
============================*/


$grid-width: 96%;
$grid-max-width: 1400px;
$grid-columns: 12;
$grid-gutters: (
    "xxs":   1.25rem,
    "xs":    1.25rem,
    "sm":    1.50rem,
    "md":    1.50rem,
    "lg":    1.75rem,
    "xl":    1.75rem,
    "xxl":   2rem,
    "grid-max-width": 1.75rem,
);


$grid-width-no-unit: 96;
$grid-max-width-no-unit: 1400;


/*=================================================
=            Media queries breakpoints            =
=================================================*/


$breakpoints: (
    "xxs":     0,
    "xs":    480,
    "sm":    768,
    "md":    992,
    "lg":   1280,
    "xl":   1440,
    "xxl":  1920,
    "grid-max-width": $grid-max-width-no-unit,
);

$breakpoint-vertical: "not all and (min-width: 768px), not all and (min-height: 550px), (orientation: portrait)";
$breakpoint-horizontal: "(min-width: 768px) and (min-height: 550px) and (orientation: landscape)";


/*===========================================
=            Images and SVG dirs            =
===========================================*/


$img-dir: "/img/layout/";
$svg-dir: "/assets/svg/layout/";



/*=================================
=            Z-indexes            =
=================================*/


$layers: (
    "goku"     : 9000,
    "modal"    : 200,
    "dropdown" : 100,
    "default"  : 1,
    "limbo"    : -999
);



/*==============================
=            Easing            =
==============================*/


$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);
