/*============================
=            Grid            =
============================*/

// Container
.grid {
    width: $grid-width;
    max-width: $grid-max-width;
    margin-right: auto;
    margin-left: auto;
}

// Row
.grid__row {
    display: flex;
    flex-wrap: wrap;

    // Media queries
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md($key)} {
            margin-right: $grid-gutter * -0.5;
            margin-left: $grid-gutter * -0.5;
        }
    }

    &.-responsive {

        > * {
            padding-top: var(--grid-gutter-half);
            padding-bottom: var(--grid-gutter-half);
        }
    }
}


// Grid and cols
.grid,
[class^="grid__col"],
[class*=" grid__col"] {
    min-height: 1px;

    // Media queries
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);
        $grid-gutter: map-get($grid-gutters, $key);

        @media #{md($key)} {
            padding-right: $grid-gutter * 0.5;
            padding-left: $grid-gutter * 0.5;
        }
    }
}



@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $bp: nth($breakpoint, 2);

    // Media queries
    @media #{md($key)} {
        $modifier: if($bp == 0, '', \@#{$key});
        $grid-gutter: map-get($grid-gutters, $key);

        .--gutter#{$modifier} {
            padding-right: $grid-gutter * 0.5;
            padding-left: $grid-gutter * 0.5;
        }

        .--no-gutter#{$modifier} {
            padding-right: 0;
            padding-left: 0;
        }

        // Generate all columns classes
        @for $i from 0 through $grid-columns {
            $class: if($bp == 0, $i, #{$i}\@#{$key});

            .grid__col--#{$class} {
                width: $i * 100% / $grid-columns
            }

            .--offset-#{$class} {
                margin-left: $i * 100% / $grid-columns
            }

            .--push-#{$class} {
                left: $i * 100% / $grid-columns
            }

            .--order-#{$class} {
                order: $i;
            }
        }
    }
}
