
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                








































































































/*----------  Button group  ----------*/

.c-btn-group {
    display: flex;

    .c-btn__el {
        width: 100%;
        height: 100%;
    }

    .c-btn:not(:first-child) {
        .c-btn__el {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .c-btn:not(:last-child) {
        .c-btn__el {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            /*
            &:hover {

                .c-btn__bg:after {
                    transform: translate(calc(100% - .25em), 0);
                }
            }

            &:active {

                .c-btn__bg:after {
                    transform: translate(calc(100% - .5em));
                }
            }
            */
        }
    }
}

.c-btn__el {
    --color-bg: #{$color-primary};

    display: inline-flex;
    align-items: center;
    padding: .75em .85em;
    color: $color-light;
    border-radius: $border-radius;
    cursor: pointer;

    transition: all .4s ease-out;

    &:hover {
        color: var(--color-bg);
        border-radius: 0;
        transition: all .4s ease-out;

        .c-btn__bg:after {
            transform: translate(0, calc(100% - .15em));
        }
    }

    &:active {

        .c-btn__bg:after {
            transform: translate(0, calc(100% - .25em));
            transition: transform .1s;
        }
    }

    &--bg-secondary {
        --color-bg: #{$color-secondary};
    }

    &--bg-light {
        --color-bg: #{$color-light};
        background-color: $color-light;

        color: $color-secondary;

        .c-btn__bg {
            background-color: $color-secondary-light;
        }
    }

    &--border {
        --color-bg: #{$color-light};

        color: $color-dark;
        border: $border-light;

        &:hover {
            color: $color-dark;
        }
    }

    &--simple {
        // --color-bg: transparent;

        padding: 0;
        color: $color-primary;

        .c-btn__bg {
            top: -.4em;
            right: -.5em;
            bottom: -.5em;
            left: -.5em;
            width: auto;
            height: auto;
            background-color: transparent;
            border-radius: 0;

            &:before {
                @include pseudo-el($height: calc(100% - .15em), $bg: $color-primary-light);
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: 50% 100%;
            }

            &:after {
                top: auto;
                bottom: 0;
                height: .15em;
                transform-origin: 50% 0;
            }

            &:after,
            &:before {
                transform: scale(1, 0);
                transition: transform .6s $out-expo;
            }
        }

        &:hover {
            color: $color-primary;

            .c-btn__bg {

                &:after,
                &:before {
                    transform: scale(1);
                }
            }
        }
    }
}

.c-btn__bg {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-primary-light;
    border-radius: inherit;
    overflow: hidden;

    &:after {
        @include pseudo-el($bg: var(--color-bg));
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        transition: transform .6s $out-expo;
    }
}

.c-btn__label {
    width: 100%;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
    user-select: none;

    & + .c-btn__icon {
        margin-left: 1em;
    }
}

.c-btn__icon {

    & + .c-btn__label {
        margin-left: 1em;
    }
}

