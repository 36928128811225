
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























































































































































.o-asset {
    border-radius: $border-radius;
    overflow: hidden;

    &--cover,
    &--contain {
        width: 100%;
        height: 100%;
    }

    &--cover .o-asset__bg {
        background-size: cover;
    }

    &--contain .o-asset__bg {
        background-size: contain;
    }

    &--animate {
        overflow: hidden;

        .o-asset__bg,
        .o-asset__img {
            transform: scale(calc(1 + var(--perc)/4));
            transform-origin: 50% 0;
            will-change: transform;
        }
    }

    // Fix border radius + overflow + transform bug
    .is-safari & {
        -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
}

.o-asset__picture {
    display: block;
    width: 100%;
}

.o-asset__img {
    @include img;
}

.o-asset__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: var(--img-xs, var(--img));
    background-position: 50% 50%;

    @media #{md("xs")} {
        background-image: var(--img-sm, var(--img));
    }

    @media #{md("sm")} {
        background-image: var(--img-md, var(--img));
    }

    @media #{md("md")} {
        background-image: var(--img-lg, var(--img));
    }

    // Retina displays
    @media (max-width: 768px) and (min-resolution: 192dpi) {
        background-image: var(--img-sm2x, var(--img));
    }

    @media (max-width: 480px) and (min-resolution: 192dpi) {
        background-image: var(--img-xs2x, var(--img));
    }
}

